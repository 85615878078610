import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import validator from 'validator';
import { Auth } from 'aws-amplify';

import TextInput from '../shared/TextInput';

function StepEmail({ onNext }) {
    const [email, setEmail] = useState('');
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const nav = useNavigate();

    useEffect(() => {
        setHasError(false);
        setErrorMessage('');

        if (!checkAllowNext()) {
            setHasError(true);
            setErrorMessage('Please enter a valid email address')
        }
    }, [email])

    const handleNext = async () => {
        if (!checkAllowNext()) {
            return;
        }

        try {
            await Auth.signIn(email, 'x');
        }
        catch (ex) {
            if (ex.code === 'UserNotFoundException') {
                return onNext(email);
            }
        }

        setHasError(true);
        setErrorMessage('An account already exists with this email address.');
    }

    const checkAllowNext = () => {
        // TODO: existing user
        return validator.isEmail(email);
    }

    return <div>
        <label className={'create-account-step-label'}>Step 2 of 5 Email</label>
        <div className={'text-block'}>
            <div className={'step-title'}>Enter an Email</div>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation
            </p>
            <TextInput label={'Email Address'} onChange={(val) => setEmail(val)} />
            {hasError && <label className={'signup-input-error'}>{errorMessage}</label>}
        </div>

        <div className={'create-account-button-container'}>
            <div className={'btn btn-secondary'} onClick={() => nav(-1)}>Back</div>
            <div className={`btn btn-primary ${checkAllowNext() ? '' : 'disabled'}`} onClick={() => handleNext()}>Next</div>
        </div>
    </div>
}

export default StepEmail