import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

function StepEmailCode({ onNext }) {
    const nav = useNavigate();

    const checkAllowNext = () => {
        return true;
    }
    const handleNext = () => {
        if (!checkAllowNext()) {
            return;
        }

        onNext();
    }

    return <div>
        <label className={'create-account-step-label'}>Step 1 of 5 Terms of Use</label>
        <div className={'text-block'}>
            <div className={'step-title'}>Terms of Use</div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation</p>

            <p>By clicking ‘I Agree'. this means you have read and understood the full terms of use and agree to comly with them.</p>

        </div>

        <div className={'create-account-button-container'}>
            <div className={'btn btn-secondary'} onClick={() => nav(-1)}>Back</div>
            <div className={`btn btn-primary ${checkAllowNext() ? '' : 'disabled'}`} onClick={() => handleNext()}>Next</div>
            { /* <Link className={'btn btn-secondary'} to={'../..'}>Cancel</Link>
            <Link className={'btn btn-primary'} to={'./email'}>I agree</Link> */ }
        </div>
    </div>
}

export default StepEmailCode;