import React from 'react';
import { Auth } from 'aws-amplify';
import { eraseCookie } from './cookieHelper';
import { selectErrorMessage, selectErrorSource } from './redux/authSlice';
import { useSelector, useDispatch, } from 'react-redux'


function ErrorPage() {
    const handleLogout = async () => {
        eraseCookie("id_token");
        eraseCookie("access_token");
        eraseCookie("refresh_token");
        localStorage.removeItem('client-id');

        await Auth.signOut();
        window.location.pathname = '/';
    }

    const errorCode = useSelector(selectErrorSource());
    const errorMessage = useSelector(selectErrorMessage());

    return <div>
        There has been an problem logging you in. {errorCode} {errorMessage}
        <button onClick={() => handleLogout()}>Logout</button>
    </div>
}

export default ErrorPage;