/*
 * Used to get content for pages, such as the terms of use page.
 */
async function getDynamicContent(contentName) {
    return new Promise((resolve) => {
        fetch(`/${contentName}`)
            .then((response) => response.text())
            .then((textResponse) => resolve(textResponse))
            .catch(() => resolve(`<h1>Unable to fetch content for "${contentName}"</h1>`))
    })
}

export default getDynamicContent;