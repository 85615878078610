import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import success from './assets/success.svg';

function ProfileConfirmed() {
    const nav = useNavigate();

    return <div>

        <div className={'complete-container'}>
            <div className={'complete-container-success'}>
                <img src={success} />
            </div>
            <div className={'complete-container-text'}>Email Address Confirmed!</div>
            <div className={'complete-container-subtext'}>Your email has beeen confirmed in Foodtrader </div>
        </div>
        <div className={'block-button-container'}>
            <button type={'button'} onClick={() => window.close()} className={'btn btn-primary btn-block'}>Close</button>
        </div>

    </div>
}


export default ProfileConfirmed;