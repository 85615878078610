import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

import { Link } from 'react-router-dom';
import TextInput from './shared/TextInput';
import CheckboxInput from './shared/CheckBoxInput';
import handleIdpLogin from './handleIdpLogin';
import { useNavigate } from 'react-router';

import Warning from './assets/warning.svg';
import eccLoading from './assets/ecc-loading.gif';


import { getProviderFile } from './getProviderFile';

import { getCookieData, saveCookieData } from './cookieData'
import { getClientID } from './getClientID';
import { setAuthState, setCurrentMFAPrompt, selectAuthState, selectCurrentUser, selectCurrentSession, setPreferredMFA, setProfileComplete } from './redux/authSlice';
import { useSelector, useDispatch } from 'react-redux'
import PasswordInput from './shared/PasswordInput';

function SignIn({ onResetPassword }) {
    const dispatch = useDispatch();

    const [username, setUsername] = useState('');
    const [rememberUsername, setRememberUsername] = useState(true);
    const [step, setStep] = useState('username');
    const [providerName, setProviderName] = useState('');
    const [isBusy, setIsBusy] = useState(false);

    const [password, setPassword] = useState('');
    const [mfaCode, setMfaCode] = useState('');

    const [authResult, setAuthResult] = useState();

    const authState = useSelector(selectAuthState);

    useEffect(() => {
        const cookieData = getCookieData();
        console.log(cookieData)
        setRememberUsername(cookieData.remember_username);
        setUsername(cookieData.username);
    }, [])

    /**
     * 
     */
    const updateCookieData = () => {
        const cookieData = {
            remember_username: rememberUsername
        }

        cookieData.username = rememberUsername ? username : '';

        saveCookieData(cookieData);
    }

    const doLogin = async () => {
        if (authState === 'sms_mfa') {
            const mfaResult = await Auth.confirmSignIn(authResult, mfaCode);
            return dispatch(setAuthState('logged_in'))
        }

        try {
            dispatch(setAuthState('logging_in'))
            updateCookieData();

            const signinResult = await Auth.signIn(username, password);

            setAuthResult(signinResult)
            dispatch(setPreferredMFA(signinResult.preferredMFA));

            if (signinResult.signInUserSession) {
                dispatch(setCurrentMFAPrompt(signinResult.attributes['custom:mfa_prompt']));
                dispatch(setProfileComplete(signinResult.attributes['custom:profile_complete']))
                return dispatch(setAuthState('logged_in'))
            }
            else if (signinResult.challengeName === 'SMS_MFA') {
                //nav('../sms_mfa')
                dispatch(setAuthState('sms_mfa'));

                // Auth.currentAuthenticatedUser().then(user => console.log('[SMS]', user));

                // console.log('Auth Challenge: ', result)
                /* 
                    "challengeName": "SMS_MFA",
                    "challengeParam": {
                        "CODE_DELIVERY_DELIVERY_MEDIUM": "SMS",
                        "CODE_DELIVERY_DESTINATION": "+*******6987"
                    }
    
                */

            }



        }
        catch (ex) {
            console.log('-- Error:', ex);
            if (ex.code === 'UserNotConfirmedException') {
                dispatch(setAuthState('unconfirmed'));
            }
            else {
                dispatch(setAuthState('incorrect'));
            }


        }
    }

    const doFederatedLogin = async (providerName) => {
        updateCookieData();
        handleIdpLogin(providerName);
    }

    const moveToSecondStep = async () => {
        setIsBusy(true);
        updateCookieData();
        const providerInfo = await getProviderFile(username);

        if (providerInfo.type === 'local') {
            return setStep('password');
        }

        setStep('federated');
        setProviderName(providerInfo.idp.label);
        setTimeout(() => doFederatedLogin(providerInfo.idp.identityProviderCode), 2000);
    }


    return <div className={'signin-parent-container'}>
        <div className={'signin-label'}>Sign in to FoodTrader</div>
        {authState === 'incorrect' && <div className={'error-container'}><img src={Warning} alt={'Warning Icon'} />The email address or password you have entered is incorrect.</div>}
        {authState === 'unconfirmed' && <div className={'error-container'}><img src={Warning} alt={'Warning Icon'} />The email address has not been confirmed. Resend confirmation email</div>}

        {step === 'federated' && <div className={'dimmed'}>
            <img src={eccLoading} />
            <div className={'signin-provider-label'}>Signing you in with {providerName}...</div>
        </div>}

        <div className={'input-container'}>
            <>
                <TextInput label={'Username'} value={username} onChange={(value) => setUsername(value)} readonly={step !== 'username'} />
                <CheckboxInput label={'Remember my Username'} value={rememberUsername} onChange={(val) => setRememberUsername(val)} />
                {step === 'username' && <button type={'button'} disabled={isBusy} className={'btn btn-primary btn-block'} onClick={() => moveToSecondStep()}>Next</button>}
            </>

            {step === 'password' && <>
                <PasswordInput label={'Password'} linkText={'Forgot password?'} linkClick={() => onResetPassword(username)} hidden={true} onChange={(value) => setPassword(value)} onEnterClick={() => doLogin()} />
                {authState === 'sms_mfa' && <label>An SMS code has been sent to {authResult?.challengeParam?.CODE_DELIVERY_DESTINATION}</label>}
                {authState === 'sms_mfa' && <TextInput label={'MFA Code'} linkText={'Forgot username?'} value={mfaCode} onChange={(value) => setMfaCode(value)} />}

                {authState === 'logging_in' && <label>Please Wait</label>}
                {authState === 'logged_in' && <label>Log In Successful!</label>}
                <button type={'button'} disabled={isBusy} className={'btn btn-primary btn-block'} onClick={doLogin}>Sign In</button>
            </>}
        </div>

        <div className={'signin-tou-container'}>
            You're signing in to FoodTrader. This website is subject to <Link to={'../terms_of_use'}>terms of use.</Link>
        </div>

        <div className={'signin-info-container'}>
            <span className={'signin-subtitle-text'}>What is FoodTrader?</span>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </div>
        <div className={'signin-create-account-container'}>
            <Link to={'../create_account'}>Create a my FoodTrader account {'>'}</Link>
        </div>
    </div>
}


export default SignIn;

