import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TextInput from '../shared/TextInput';

function StepEmailCode({ onNext }) {
    const [emailCode, setEmailCode] = useState('');
    const nav = useNavigate();

    return <div>
        <label className={'create-account-step-label'}>Step 2 of 3 Code</label>
        <div className={'text-block'}>
            <div className={'step-title'}>Enter Code</div>
            <p>
                If the email entered matches an account in Foodtrader, you will receive an email with a code to reset your account.
                Please enter that code below.
                [Resend button]
            </p>
            <TextInput label={'Enter Code'} onChange={(val) => setEmailCode(val)} />
        </div>

        <div className={'create-account-button-container'}>
            <div className={'btn btn-secondary'} onClick={() => nav(-1)}>Back</div>
            <div className={'btn btn-primary'} onClick={() => onNext(emailCode)}>Next</div>
        </div>
    </div>
}

export default StepEmailCode;