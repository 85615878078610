function _writeToLog(level, ...args) {
    if ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') || level === 'warn' || level === 'error') {
        console.log(...args);
    }
}

function buildLogger() {
    return {
        debug: (...args) => _writeToLog('debug', ...args),
        log: (...args) => _writeToLog('log', ...args),
        warn: (...args) => _writeToLog('warn', ...args),
        error: (...args) => _writeToLog('error', ...args)
    }
}

export default buildLogger();
