import React, { useState } from 'react';
import _uniqueId from 'lodash/uniqueId';

function SelectInput({ label, value, linkClick, linkText, onChange, options }) {
    const [id] = useState(_uniqueId('input-'));


    return <div className={'form-group'}>
        <div className="form-row">
            <div className="col">
                <div className={'input-label-container'}>
                    <label htmlFor={id}>{label}</label>
                    <small id={`${id}-link`} className={'form-text text-muted link-text'} onClick={() => linkClick()}>{linkText}</small>
                </div>

                <select id={id} className="form-control" value={value} onChange={(event) => onChange(event.target.value)}>
                    <option>--- Please Select ---</option>
                    {options.map(op => <option>{op}</option>)}
                </select>
            </div>
            <div className="col" />
        </div>
    </div>
}

export default SelectInput;