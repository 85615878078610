
function getServiceNowEnv() {
    let serviceNowEnv = '';

    switch (process.env.REACT_APP_CONFIG_ENV) {
        case 'local':
        case 'devops':
            serviceNowEnv = 'eccdev04';
            break;
        case 'poc':
            serviceNowEnv = 'eccdemo';
            break;
        case 'oscar-non-prod':
            serviceNowEnv = 'oscar-test';
            break;
        case 'oscar-prod':
            serviceNowEnv = 'oscar';
            break;
    }

    return serviceNowEnv;
}

export default function requestNewCode(currentCode) {
    return new Promise((resolve, reject) => {
        const requestNewCodeUrl = `https://${getServiceNowEnv()}.service-now.com/api/x_acn62_intg_core/user_invitation_code/reissue/${currentCode}`
        fetch(requestNewCodeUrl).then((response) => response.json()).then((response) => {
            console.log(response);

            if (response.result.error) {
                return reject(response.result.errorReason);
            }

            return resolve();

        }).catch(() => reject())
    })
}