import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TextInput from '../shared/TextInput';

function StepEmailCode({ onNext }) {
    const [emailCode, setEmailCode] = useState('');
    const nav = useNavigate();

    return <div>
        <label className={'create-account-step-label'}>Step 3 of 5 Code</label>
        <div className={'text-block'}>
            <div className={'step-title'}>Enter Code</div>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation
            </p>
            <TextInput label={'Enter Code'} onChange={(val) => setEmailCode(val)} />
        </div>

        <div className={'create-account-button-container'}>
            <div className={'btn btn-secondary'} onClick={() => nav(-1)}>Back</div>
            <div className={'btn btn-primary'} onClick={() => onNext(emailCode)}>Next</div>
        </div>
    </div>
}

export default StepEmailCode;