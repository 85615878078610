import React, { useState } from 'react';
import _uniqueId from 'lodash/uniqueId';

function TextInput({ label, value, linkClick, linkText, onChange, onEnterClick, readonly }) {
    const [id] = useState(_uniqueId('input-'));

    let inputType = 'email';

    const handleKeyUp = (event) => {
        console.log(event.keyCode === 13, onEnterClick, typeof (onEnterClick) === 'function');
        if (event.keyCode === 13 && typeof (onEnterClick) === 'function') {
            onEnterClick();
        }
    }

    return <div className={'form-group'}>
        <div className={'row'}>
            <div className={'col-12'}>
                <div className={'float-left'}><label htmlFor={id} className={'ecc-field-label'}>{label}</label></div>
                <div className={'float-right'}><label id={`${id}-link`} className={'form-text text-muted link-text float-right'} onClick={() => linkClick()}>{linkText}</label></div>
            </div>
        </div>
        <input type={inputType} readOnly={readonly} className={'form-control'} id={id} value={value} onChange={(e) => onChange(e.target.value)} onKeyUp={handleKeyUp} />
    </div>
}

export default TextInput;