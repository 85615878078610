import React, { useEffect, useState } from 'react';
import getDynamicContent from './getDynamicContent';
import eccLoading from './assets/ecc-loading.gif';

function TermsOfUse(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [content, setContent] = useState();

    useEffect(() => {
        setIsLoading(true);
        getDynamicContent('terms_of_use.html').then((pageContent) => {
            setContent(pageContent);
            setIsLoading(false);
        })
    }, []);

    /* If isLoading, we show the spinner screen instead */
    if (isLoading) {
        return <div className={'signin-parent-container'}>
            <div className={'signin-label'}>Sign in to FoodTrader</div>
            <div className={'overlayImg'}><img src={eccLoading} /></div>
        </div>
    }


    return <div dangerouslySetInnerHTML={{ __html: content }} />
}

export default TermsOfUse;