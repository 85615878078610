import React, { useState } from 'react';
import _uniqueId from 'lodash/uniqueId';

function PasswordInput({ label, value, linkClick, linkText, onChange, onEnterClick, readonly }) {
    const [id] = useState(_uniqueId('input-'));
    const [isHidden, setIsHidden] = useState(true)

    const handleKeyUp = (event) => {
        console.log(event.keyCode === 13, onEnterClick, typeof (onEnterClick) === 'function');
        if (event.keyCode === 13 && typeof (onEnterClick) === 'function') {
            onEnterClick();
        }
    }

    return <div className={'form-group'}>
        <div className={'row'}>
            <div className={'col-12'}>
                <div className={'float-left'}><label htmlFor={id} className={'ecc-field-label'}>{label}</label></div>
                <div className={'float-right'}><label id={`${id}-link`} className={'ecc-field-link form-text link-text float-right'} onClick={() => linkClick()}>{linkText}</label></div>
            </div>
        </div>
        <div class="input-group mb-3">

            <input type={isHidden ? 'password' : 'text'} readOnly={readonly} className={'form-control'} id={id} value={value} onChange={(e) => onChange(e.target.value)} onKeyUp={handleKeyUp} />
            <div class="input-group-append">
                <span class="input-group-text" style={{ backgroundColor: 'unset' }}><ion-icon onClick={() => setIsHidden(!isHidden)} name={isHidden ? 'eye-outline' : 'eye-off-outline'}></ion-icon></span>
            </div>
        </div>
    </div>
}

export default PasswordInput;