
import { useEffect } from 'react';
import { getUrlParams } from './getUrlParams';
import { Auth } from 'aws-amplify';
import { eraseCookie } from './cookieHelper';
import eccLoading from './assets/ecc-loading.gif';

function Logout() {
    useEffect(() => {
        const { post_logout_redirect_uri } = getUrlParams();

        eraseCookie("id_token");
        eraseCookie("access_token");
        eraseCookie("refresh_token");
        localStorage.removeItem('client-id');

        Auth.signOut().then(() => {
            window.location = post_logout_redirect_uri;
        });
    }, []);

    return <div className={'signin-parent-container'}>
        <div className={'signin-label'}>Logging out of FoodTrader...</div>
        <div className={'overlayImg'}><img src={eccLoading} /></div>
    </div>
}

export default Logout;