import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import validator from 'validator';

import PhoneInput from '../shared/PhoneInput';

function StepFour({ onNext }) {
    const [mobile, setMobile] = useState('');
    const [hasError, setHasError] = useState(false);

    const nav = useNavigate();

    useEffect(() => {
        setHasError(!checkAllowNext());
    }, [mobile])

    const handleNext = () => {
        if (!checkAllowNext()) {
            return;
        }

        onNext(mobile);
    }

    const checkAllowNext = () => {
        if (!mobile || !mobile.phoneNumber) {
            return false;
        }

        return validator.isMobilePhone(mobile.phoneNumber, 'en-AU')
    }


    return <div>
        <label className={'create-account-step-label'}>Step 4 of 5 Mobile</label>
        <div className={'text-block'}>
            <div className={'step-title'}>Enter your Mobile</div>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation
            </p>
            <PhoneInput label={'Mobile Number'} onChange={(val) => setMobile(val)} />
            {hasError && <label className={'signup-input-error'}>Please enter a valid mobile phone number</label>}
        </div>

        <div className={'create-account-button-container'}>
            <div className={'btn btn-secondary'} onClick={() => nav(-1)}>Back</div>
            <div className={`btn btn-primary ${checkAllowNext() ? '' : 'disabled'}`} onClick={() => handleNext()}>Next</div>
        </div>
    </div>
}

export default StepFour;