import { useState, useEffect } from 'react';
import { Auth, nav } from "aws-amplify";
import { selectAuthState, selectCurrentMFAPromt, selectPreferredMFA } from './redux/authSlice';
import { useSelector, useDispatch, } from 'react-redux'

import TextInput from './shared/TextInput';
import CheckBoxInput from './shared/CheckBoxInput';
import Warning from './assets/warning.svg'
import { getVariableValues } from 'graphql/execution/values';
import SelectInput from './shared/SelectInput';
import { States } from './Constants';
import { first } from 'lodash';
import { useNavigate } from 'react-router';
import PhoneInput from './shared/PhoneInput';
import titles from './assets/titles';

const DEFAULT_PASSWORD = '********';

function EditProfile({ from }) {
    const [currentUser, setCurrentUser] = useState();
    const [enrollSmsCode, setEnrollSmsCode] = useState();
    const [smsState, setSmsState] = useState();

    /* Form Fields */
    const [title, setTitle] = useState('');
    const [firstName, setFirstName] = useState();
    const [middleName, setMiddleName] = useState();
    const [lastName, setLastName] = useState();
    const [username, setUsername] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [mobilePhoneNumber, setMobilePhoneNumber] = useState();
    /* End Form Fields */

    const [smsEnabled, setSmsEnabled] = useState();

    const [updateError, setUpdateError] = useState();

    const authState = useSelector(selectAuthState);
    const currentMFAPrompt = useSelector(selectCurrentMFAPromt);
    //const currentPreferredMFA = useSelector(selectPreferredMFA);

    const [currentPreferredMFA, setCurrentPreferredMFA] = useState();

    const nav = useNavigate();

    useEffect(() => {
        Auth.currentAuthenticatedUser().then((user) => {
            console.log(user)
            setCurrentUser(user);

            setTitle(user.attributes ? user.attributes['custom:title'] : '');
            setFirstName(user.attributes?.given_name);
            setMiddleName(user.attributes?.middle_name);
            setLastName(user.attributes?.family_name);

            setUsername(user.attributes?.email);
            setPhoneNumber(user.attributes ? user.attributes['custom:landline_number'] : '');
            setMobilePhoneNumber(user.attributes?.phone_number);

            /* setAddress(user.attributes ? user.attributes['custom:street_address'] : '');
            setSuburb(user.attributes ? user.attributes['custom:city'] : '');
            setState(user.attributes ? user.attributes['custom:state'] : '');
            setPostCode(user.attributes ? user.attributes['custom:postal_code'] : ''); */

            // setPassword(DEFAULT_PASSWORD);

            setCurrentPreferredMFA(user.preferredMFA);
            setSmsEnabled(user.preferredMFA === 'SMS_MFA');
        });
    }, []);


    const hasChanged = (attrName, value) => {
        return currentUser.attributes[attrName] !== value;
    }

    const doUpdate = async () => {
        if (currentPreferredMFA !== 'SMS_MFA' && smsEnabled) {
            if (enrollSmsCode.length != 6) {
                return setUpdateError('SMS Code incomplete');
            }

            await Auth.verifyUserAttributeSubmit(currentUser, 'phone_number', enrollSmsCode);
            await Auth.setPreferredMFA(currentUser, 'SMS_MFA');
        }

        if (currentPreferredMFA === 'SMS_MFA' && !smsEnabled) {
            await Auth.setPreferredMFA(currentUser, 'NOMFA');
        }

        const valuesToUpdate = {};

        if (hasChanged('email', username)) {
            valuesToUpdate.email = username;
        }

        if (hasChanged('custom:title', title)) {
            valuesToUpdate['custom:title'] = title;
        }

        if (hasChanged('custom:landline_number', phoneNumber)) {
            valuesToUpdate['custom:landline_number'] = phoneNumber;
        }


        if (hasChanged('given_name', firstName)) {
            valuesToUpdate['given_name'] = firstName;
        }

        if (hasChanged('middle_name', middleName)) {
            valuesToUpdate['middle_name'] = middleName;
        }

        if (hasChanged('family_name', lastName)) {
            valuesToUpdate['family_name'] = lastName;
        }

        valuesToUpdate['custom:profile_complete'] = '1';

        console.log(Object.keys(valuesToUpdate).length > 0)

        if (Object.keys(valuesToUpdate).length > 0) {
            console.log('starting update', valuesToUpdate)
            try {

                Object.keys(valuesToUpdate).forEach(key => {
                    const update = {};
                    update[key] = valuesToUpdate[key];

                    Auth.updateUserAttributes(currentUser, update).then((result) => console.log('Updated:', result, update)).catch((ex) => console.log(update, ex))
                })
            }
            catch (ex) {
                console.log(ex)
            }

        }

        if (from === 'complete_profile') {
            nav('../loggedin');
        }
    }

    const doVerifyPhoneNumber = () => {
        Auth.verifyUserAttribute(currentUser, 'phone_number').then(() => {
            setSmsState('sent');
        })
    }

    const handleSMSButtonClick = () => {
        if (hasChanged('phone_number'), phoneNumber) {
            Auth.updateUserAttributes(currentUser, { phone_number: phoneNumber }).then(() => {
                doVerifyPhoneNumber();
            })
        }
        else {
            doVerifyPhoneNumber();
        }
    }

    return <div className={'signin-parent-container'}>
        <div className={'signin-label'}>FoodTrader Profile</div>
        {from === 'complete_profile' && <div>Before logging in for the first time, you need to complete your profile</div>}
        {updateError && <div className={'error-container'}><img src={Warning} />{updateError}</div>}
        {currentUser && <div className={'input-container'}>

            <SelectInput label={'Title'} value={title} onChange={(value) => setTitle(value)} options={titles} />
            <TextInput label={'First Name'} value={firstName} onChange={(value) => setFirstName(value)} />
            <TextInput label={'Middle Name'} value={middleName} onChange={(value) => setMiddleName(value)} />
            <TextInput label={'Last Name'} value={lastName} onChange={(value) => setLastName(value)} />
            <TextInput label={'Email'} value={username} onChange={(value) => setUsername(value)} />

            <PhoneInput label={'Phone Number'} value={phoneNumber} onChange={(value) => setPhoneNumber(value)} />
            <PhoneInput label={'Mobile Number'} value={mobilePhoneNumber} onChange={(value) => setMobilePhoneNumber(value)} />

            <CheckBoxInput label={'Enable SMS Multifactor Authentication'} value={smsEnabled} onChange={(val) => setSmsEnabled(val)} />
            {smsEnabled && currentPreferredMFA !== 'SMS_MFA' && <div>
                <label>To enable SMS based MultiFactor Authentication, a SMS code needs to be sent to your phone and veified.</label>
                <button className={'btn btn-link btn-sm'} onClick={handleSMSButtonClick}>Send SMS</button>
                {smsState === 'sent' && <TextInput label={'MFA Code'} value={enrollSmsCode} onChange={(value) => setEnrollSmsCode(value)} />}
            </div>}

            <button type={'button'} className={'btn btn-primary'} onClick={doUpdate}>Update</button>
        </div>}

        {!currentUser && <div className={'input-container'}>Loading...</div>}


    </div>
}

/*
         <TextInput label={'Password'} value={password} hidden={true} onChange={(value) => setPassword(value)} />
           
            <TextInput label={'Address'} value={address} onChange={(value) => setAddress(value)} />

            <div className={'form-row'}>
                <div className={'col-md-6'}>
                    <TextInput label={'Suburb'} value={suburb} onChange={(value) => setSuburb(value)} />
                </div>
                <div className={'col-md-4'}>
                    <SelectInput label={'State'} options={States} value={state} onChange={(value) => setState(value)} />
                </div>
                <div className={'col-md-2'}>
                    <TextInput label={'Post Code'} value={postCode} onChange={(value) => setPostCode(value)} />
                </div>
            </div>
         
            {authState === 'sms_mfa' && <label>An SMS code has been sent to {authResult?.challengeParam?.CODE_DELIVERY_DESTINATION}</label>}
            {authState === 'sms_mfa' && <TextInput label={'MFA Code'} linkText={'Forgot username?'} value={mfaCode} onChange={(value) => setMfaCode(value)} />}
       {authState === 'incorrect' && <div className={'error-container'}><img src={Warning} />The email address or password you have entered is incorrect.</div>}
        {authState === 'unconfirmed' && <div className={'error-container'}><img src={Warning} />The email address has not been confirmed. Resend confirmation email</div>}
 
            */


export default EditProfile;