import { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router';
import FoodTraderLogo from './assets/foodtrader-logo.svg'
import './App.css';
import SignIn from './SignIn';
import CreateAccount from './CreateAccount'
import { Auth } from 'aws-amplify';
import ResetPassword from './ResetPassword';
import TermsOfUse from './TermsOfUse';
import MFASignup from './MFASignup';
import EditProfile from './EditProfile';
import AuthStateChangeHandler from './AuthStateChangeHandler';
import SignUpProfile from './SignUpProfile';
import ProfileCreated from './ProfileCreated';
import { useDispatch } from 'react-redux'
import { setAuthState, setProfileComplete, setCurrentMFAPrompt, setError, setErrorSource, setErrorMessage } from './redux/authSlice';
import ErrorPage from './ErrorPage';
import eccLoading from './assets/ecc-loading.gif';
import ProfileConfirmed from './ProfileConfirmed';
import Logout from './Logout';

const PathDisplay = () => {
  const location = useLocation();
  return <label>Current Path: {location?.pathname + location.search}</label>
}

function App() {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const nav = useNavigate();

  useEffect(() => {
    if (window.location.pathname === '/email_confirmed') {
      return nav('email_confirmed');
    }

    if (window.location.pathname === '/signup') {
      return nav('signup');
    }

    if (window.location.pathname === '/logout') {
      return nav('logout');
    }


    if (window.location.pathname === '/no-redirect') {
      dispatch(setError(true));
      dispatch(setErrorSource('client_error'));
      dispatch(setErrorMessage('No redirect configured'))
      return nav('error')
    }

    const currentUrlParams = new URLSearchParams(window.location.search.toString());
    console.log(currentUrlParams.has('error'))
    if (currentUrlParams.has('error')) {
      dispatch(setError(true));
      dispatch(setErrorSource(currentUrlParams.get('error')));
      dispatch(setErrorMessage(currentUrlParams.get('error_description')))
      return nav('error')
    }

    setIsLoading(true);

    Auth.currentAuthenticatedUser()
      .then((user) => {
        dispatch(setCurrentMFAPrompt(user.attributes['custom:mfa_prompt']));
        dispatch(setProfileComplete(user.attributes['custom:profile_complete']))
        dispatch(setAuthState('logged_in'))
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        nav('signin');
      });
  }, []);

  return (
    <div className="App">
      <AuthStateChangeHandler />
      <nav class="navbar fixed-top navbar-light bg-light app-header-bar">
        <img src={FoodTraderLogo} />
      </nav>
      <div className={'app-header-bar-nav-container'}>
        <a href={''} onClick={() => nav(-1)} >{'<'} Back</a>
      </div>

      <div className={'app-root'}>
        {isLoading && <div className={'overlayImg'}><img src={eccLoading} /></div>}

        <Routes>
          <Route path={'/'} element={<label>Loading...</label>} />
          <Route path={'/create_account/*'} element={<CreateAccount />} />
          <Route path={'/signin/*'} element={<SignIn onResetPassword={() => nav('reset_password')} />} />
          <Route path={'/reset_password/*'} element={<ResetPassword />} />

          <Route path={'/terms_of_use/*'} element={<TermsOfUse />} />
          <Route path={'/mfa_signup/*'} element={<MFASignup />} />
          <Route path={'/profile/*'} element={<EditProfile />} />
          <Route path={'/complete_profile/*'} element={<EditProfile from={'complete_profile'} />} />
          <Route path={'/signup/*'} element={<SignUpProfile />} />
          <Route path={'/complete'} element={<ProfileCreated />} />
          <Route path={'/email_confirmed'} element={<ProfileConfirmed />} />

          <Route path={'/logout'} element={<Logout />} />

          {/* Error Handling */}
          <Route path={'/error'} element={<ErrorPage />} />
        </Routes>
      </div>
      <nav class="navbar fixed-bottom navbar-light bg-blue">
        Footer <br />
        <PathDisplay />
      </nav>
    </div>
  );
}

export default App;
