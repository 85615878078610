export default async function getSignupCodeDetails(signupCode, fullDetails) {
    let fetchUrl = '';

    if (window.location.hostname.toString() === 'localhost') {
        //          https://sczfczym4j.execute-api.ap-southeast-2.amazonaws.com/devops/invitations/e5b738c8-c673-4eb0-91c0-1afd382b1351
        fetchUrl = `https://poc-auth.eccnow.com.au/invitations/${signupCode}`;
    }
    else {
        fetchUrl = `https://${window.location.hostname.toString()}/invitations/${signupCode}`;
    }

    if (fullDetails) {
        fetchUrl += '?details=true'
    }

    return new Promise((resolve) => {
        fetch(fetchUrl)
            .then((response) => response.json())
            .then((jsonResponse) => resolve(jsonResponse))
            .catch((ex) => ({ error: true, message: ex.message }))
    });
}