/**
 * Gets the current URL Search Parameters in a key/value object
 * @returns {object} Flat object containing the key/values contained in the URL Search Parameters
 */

export function getUrlParams() {
    const paramsToReturn = {};

    const urlParams = new URLSearchParams(window.location.search.toString());

    urlParams.forEach((value, key) => paramsToReturn[key] = value);
    console.warn('[DEBUG]', paramsToReturn);
    return paramsToReturn;
}