import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PasswordInput from '../shared/PasswordInput';
import TextInput from '../shared/TextInput';

function StepPassword({ onNext }) {
    const nav = useNavigate();

    const [password, setPassword] = useState('');
    const [passwordSecond, setPasswordSecond] = useState('');
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (password !== passwordSecond) {
            setHasError(true);
            setError('Passwords do not match');
            return;
        }

        if (password.length < 10 || !/\d{2,}/gm.test(password)) {
            setHasError(true);
            setError('Password does not meet complexity requirements');
            return;
        }

        setHasError(false);
        setError('');

    }, [password, passwordSecond]);

    const handleNext = () => {
        if (!checkAllowNext()) {
            return;
        }

        onNext(password);
    }

    const checkAllowNext = () => {
        return !hasError;
    }

    /*return <div>
        <label className={'create-account-step-label'}>Step 4 of 5 Mobile</label>
        <div className={'text-block'}>
            <div className={'step-title'}>Enter your Mobile</div>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation
            </p>

            {hasError && <label className={'signup-input-error'}>Please enter a valid mobile phone number</label>}
        </div>

        <div className={'create-account-button-container'}>
            <div className={'btn btn-secondary'} onClick={() => nav(-1)}>Back</div>
            <div className={`btn btn-primary ${checkAllowNext() ? '' : 'disabled'}`} onClick={() => handleNext()}>Next</div>
        </div>
    </div>*/

    return <div>
        <label className={'create-account-step-label'}>Step 5 of 5 Password</label>
        <div className={'text-block'}>
            <div className={'step-title'}>Choose a password</div>
            <p>
                Your password must:
                <ul>
                    <li>have at least 10 characters</li>
                    <li>include at least 1 lower case, 1 upper case, 1 number and 1 special character</li>
                </ul>
            </p>
            <PasswordInput label={'Password'} onChange={(val) => setPassword(val)} />
            <PasswordInput label={'Re-enter Password'} onChange={(val) => setPasswordSecond(val)} />
            {hasError && <label className={'signup-input-error'}>{error}</label>}
        </div>

        <div className={'create-account-button-container'}>
            <div className={'btn btn-secondary'} onClick={() => nav(-1)}>Back</div>
            <div className={`btn btn-primary ${checkAllowNext() ? '' : 'disabled'}`} onClick={() => handleNext()}>Next</div>
        </div>
    </div>
}

export default StepPassword;