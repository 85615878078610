import _ from 'lodash'
import { getCookie, setCookie } from './cookieHelper'

const defaultCookie = {
    remember_username: true,
    username: ''
}

export function getCookieData() {
    const rawCookieData = getCookie('signin');

    if (!rawCookieData || rawCookieData === '') {
        return _.clone(defaultCookie);
    }

    try {
        return JSON.parse(rawCookieData);
    }
    catch (ex) {
        console.error(`Error attempting to parse cookie data: ${ex.message}`);
        return _.clone(defaultCookie);
    }
}


export function saveCookieData(data) {
    console.log('saveCookieData', data);

    const updatedData = _.merge(getCookieData(), data);

    const expiryDate = new Date();
    expiryDate.setFullYear(expiryDate.getFullYear() + 1);

    setCookie('signin', JSON.stringify(updatedData), expiryDate);
}